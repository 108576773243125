import request from '@/utils/request'


export function list(data) {
  return request({
    url: '/demand-information/list',
    method: 'get',
    params: data
  })
}
export function technicalFieldTypeList() {
  return request({
    url: '/demand-information/technicalFieldTypeList',
    method: 'get',
  })
}

export function getDemandInformationDetails(data) {
  return request({
    url: '/demand-information/detail',
    method: 'get',
    params: data
  })
}

export function editDemand(data) {
  return request({
    url: '/demand-information/updateDemandInformation',
    method: 'Put',
    data: data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function addDemandInformation(data) {
  return request({
    url: '/demand-information/addDemandInformation',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteDemandInformation(data) {
  return request({
    url: '/demand-information/deleteDemandInformation',
    method: 'delete',
    params: data
  })
}
