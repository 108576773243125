<template>
  <div class="addDemand">
<!--    <el-drawer
      :title="title"
      :visibleA.sync="drawer"
      @close="turnOff"
      size="60%"
    >-->
    <div class="add-heda" >
      <div class="add-heda-text">发布需求</div>
    </div>
      <div class="demand_content">

        <a-form-model ref="formB" :model="formB" :rules="rules" :label-col="{ span: 5 }"  :wrapper-col="{ span: 15}">
          <a-form-model-item label="需求类别:" prop="category" >
            <a-select v-model="formB.category"  placeholder="请选择需求类别" class="form-input">
              <a-select-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
<!--            <a-input placeholder="请输入需求类别" v-model="form.category" style="width: 435px;height: 46px;"></a-input>-->
          </a-form-model-item>
          <a-form-model-item label="技术领域:" prop="technicalField" >
            <div>
              <a-checkbox :indeterminate="isIndeterminate"  v-model="checkAll" @change="handleCheckAllChange">全选
              </a-checkbox>
              <a-checkbox-group
                  v-model="formB.technicalField"
                  @change="handleCheckedCitiesChange">
                <a-checkbox v-for="city in cities" :value="city.id" :key="city.id">{{ city.name }}</a-checkbox>
              </a-checkbox-group>
            </div>
          </a-form-model-item>
          <a-form-model-item label="需求标题:" prop="title" >
            <a-input placeholder="请输入需求标题" style="width: 435px;height: 46px;" max-length="255" v-model="formB.title"></a-input>
          </a-form-model-item>
          <a-form-model-item label="需求描述:" prop="description" >
<!--            <div @mouseover="asdasd()">-->
            <Tinymce @asdasd="asdasd" ref="Tinymce"  v-model="formB.description" width="100%"></Tinymce>
<!--            </div>-->
          </a-form-model-item>
          <a-form-model-item label="合作模式:" prop="cooperationMode" >
            <a-radio-group v-model="formB.cooperationMode">
            <a-radio  :value="Number(0)">合作模式</a-radio>
            <a-radio  :value="Number(1)">委托开发</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="需求报酬:" prop="rewardCurrencyType" >
            <a-row class="a-ant-col">
            <a-col :span="6">
              <a-form-model-item prop="rewardCurrencyType">
                <a-select v-model="formB.rewardCurrencyType"  placeholder="请选择报酬类型" >
                  <a-select-option
                      v-for="item in rewardCurrencyTypeList"
                    :key="item.value"
                    :value="item.value">
                    {{item.label}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="9" style="margin-right:10px">
              <a-form-model-item prop="payAmount"  v-if="formB.rewardCurrencyType===0">
                <a-input style="width: 270px;height: 46px;margin-right:0px" v-model="formB.payAmount" max-length="11" type="number" placeholder="请输入报酬金额"></a-input>
              </a-form-model-item>
            </a-col>
            <span v-if="formB.rewardCurrencyType===0">元 <span style="color:red">(不高于)</span></span>
            </a-row>
          </a-form-model-item>
          <a-form-model-item label="需求完成时间:" prop="completionTime" >
               <a-date-picker
                   class="form-input"
                   format="yyyy-MM-DD"
                   v-model="formB.completionTime"
                   type="date"
                   placeholder="选择日期">
               </a-date-picker>

          </a-form-model-item>
          <a-form-model-item label="需求有效期:" prop="demandPeriod" >
            <a-row>
              <a-col :span="4">
                <a-select v-model="demandPeriodValue" @change="changedemandPeriod()"  placeholder="请选择有效时间" >
                  <a-select-option
                      v-for="item in yueList"
                      :key="item.value"
                      :value="item.value">
                    {{item.label}}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="6">
                <a-date-picker
                    style="width: 315px"
                    class="form-input"
                    v-model="formB.demandPeriod"
                    type="date"
                    placeholder="选择日期">
                </a-date-picker>
              </a-col>
            </a-row>
          </a-form-model-item>
          <div style="display: flex;justify-content: space-between;margin-left: 145px">
            <div>
              <a-form-model-item label="发布单位:" prop="unitInside" :label-col="{ span: 8 }" :wrapper-col="{ span: 15}" >
                <a-input placeholder="请输入发布单位" max-length="255" v-model="formB.unitInside"></a-input>
              </a-form-model-item>
              <a-form-model-item label="联系人:" prop="personInside" :label-col="{ span: 8 }" :wrapper-col="{ span: 15}" >
                <a-input placeholder="请输入联系人" max-length="255" v-model="formB.personInside"></a-input>
              </a-form-model-item>
              <a-form-model-item label="联系人手机:" prop="phoneInside" :label-col="{ span: 8 }" :wrapper-col="{ span: 15}" >
                <a-input placeholder="请输入联系人手机" max-length="255" v-model="formB.phoneInside"></a-input>
              </a-form-model-item>
            </div>
            <div style="margin-left: 100px;font-size: 14px;color: #606266;margin-top: 10px;">对外信息</div>
            <div style="margin-right: 250px">
              <div>
                <a-form-model-item label="发布单位:" prop="unitExternal" :label-col="{ span: 8 }" :wrapper-col="{ span: 15}" >
                  <a-input placeholder="请输入发布单位" max-length="255" v-model="formB.unitExternal"></a-input>
                </a-form-model-item>
                <a-form-model-item label="联系人:" prop="personExternal" :label-col="{ span: 8 }" :wrapper-col="{ span: 15}" >
                  <a-input placeholder="请输入联系人" max-length="255" v-model="formB.personExternal"></a-input>
                </a-form-model-item>
                <a-form-model-item label="联系人手机:"  :label-col="{ span: 8 }" :wrapper-col="{ span: 15}">
                  <a-input placeholder="请输入联系人手机" max-length="255" v-model="formB.phoneExternal"></a-input>
                </a-form-model-item>
              </div>
            </div>
          </div>
          <a-form-model-item label="是否服务机构推荐录入：" >
            <a-checkbox v-model="formB.isInstitutions" :true-label="Number(1)" :false-label="Number(0)"></a-checkbox>
          </a-form-model-item>
          <a-form-model-item label="是否发布：" prop="status">
            <a-radio-group v-model="formB.status">
              <a-radio  :value="Number(1)">是</a-radio>
              <a-radio  :value="Number(0)">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
        <div  style="margin: 0px 0 0 0px;text-align: center">
          <a-button  type="" @click="goDemand()" style="margin-right: 20px;" >
<!--                    style="margin-right: 20px;width: 110px;height: 35px">-->
            返回需求列表
          </a-button>
          <a-button type="primary" @click="submit">
            发布
          </a-button>
          <a-modal v-model="visibleA" title="需求发布" @ok="handleOk">
            <p>是否确认发布</p>
            <p></p>
            <p></p>
          </a-modal>
        </div>
      </div>
<!--    </el-drawer>-->
  </div>
</template>

<script>
import moment from 'moment'
import Tinymce from '../../../components/Tinymce'
import {getDictType} from "../../../api/company/dictType";
import {addDemandInformation} from '../../../api/demand'

export default {
  name: "addDemand",
  components: {Tinymce},
  data() {
    return {
      demandPeriodValue:undefined,
      visibleA: false,
      categoryList:[],
      disable: false,
      yueList:[
        {label: '半个月', value: 0},
        {label: '一个月', value: 1},
        {label: '三个月', value: 3},
        {label: '半年', value: 6},
        {label: '一年', value: 12},
      ],
      rewardCurrencyTypeList: [
        {label: '货币', value: 0},
        {label: '面议', value: 1}
      ],
      drawer: false,
      formB: {
        release_object:undefined,
        type:1,
        status:null,
        category: undefined,
        technicalField: [],
        title: null,
        description: null,
        cooperationMode: null,
        rewardCurrencyType: undefined,
        payAmount: null,
        completionTime: null,
        demandPeriod: null,
        unitInside: null,
        personInside: null,
        phoneInside: null,
        unitExternal: null,
        personExternal: null,
        phoneExternal: null,
        isInstitutions: null,
      },
      checkAll: false,
      checkedCities: [],
      cities: [],
      isIndeterminate: false,
      rules: {
        status: [
          {required: true, message: '请选择是否发布', trigger: 'change'}
        ],
        category: [
          {required: true, message: '请输入需求类别', trigger: 'blur'}
        ],
        technicalField: [
          {required: true, message: '请选择技术领域', trigger: 'change'}
        ],
        title: [
          {required: true, message: '请输入需求标题', trigger: 'blur'}
        ],
        description: [
          {required: true, message: '请输入需求描述', trigger: 'blur'}
        ],
        cooperationMode: [
          {required: true, message: '请选择合作模式', trigger: 'blur'}
        ],
        rewardCurrencyType: [
          {required: true, message: '请选择报酬类型', trigger: 'change'}
        ],
        payAmount: [
          {required: true, message: '请输入报酬金额', trigger: 'blur'}
        ],
        unitInside: [
          {required: true, message: '请输入发布单位', trigger: 'change'}
        ],
        demandPeriod: [
          {required: true, message: '请选择需求有效期', trigger: 'change'}
        ],
        completionTime: [
          {required: true, message: '请输入需求完成时间', trigger: 'change'}
        ],
        personInside: [
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        phoneInside: [
          {required: true, message: '请输入联系人手机', trigger: 'blur'}
          ,{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号！' ,trigger: 'blur'}
        ],
        unitExternal: [
          {required: true, message: '请输入发布单位', trigger: 'blur'}
        ],
        personExternal: [
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        phoneExternal: [
          {required: true, message: '请输入联系人手机', trigger: 'blur'}
          ,{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号！' ,trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    changedemandPeriod(){
      //console.log("===========")
      if (this.demandPeriodValue===0){
       this.formB.demandPeriod= moment().add(+15,'day');
      }else {
        this.formB.demandPeriod= moment().add(+this.demandPeriodValue,'month');
      }
      //console.log(this.formB.demandPeriod)
    },
    asdasd(){
        this.$refs.formB.validateField( 'description')
    },
    goDemand(){
      this.$router.push('/demand/demand')
    },
   /* flushParentList() {
      this.$emit('getFetchData')
    },*/
    submit() {
      //console.log(this.formB,"asda")
      this.$refs['formB'].validate(valid => {
        if (valid) {
          this.visibleA = true;
        } else {
          return false;
        }
      });
    },
    handleOk(e) {
      console.log(e);
      this.addDemand()
      this.visibleA = false;
    },
   async addDemand(){
        this.formB.technicalField= this.formB.technicalField.join(",")
     await addDemandInformation(this.formB).then(()=>{
        this.$message.success('发布成功')
        this.$router.go(-1);
      })
     this.turnOff();
    },
    turnOff() {
      this.$refs.formB.resetFields()
    },
   /* show(id, type) {
      /!*technicalFieldTypeList().then(res => {
        this.cities = res.data
      })*!/
      this.update = id != null;
      if (type === 1) {
        this.disable = true
        this.title = '查看'
      } else {
        this.disable = false
        this.title = this.update ? '编辑' : '发布'
      }
      if (id !== null) {
        getDemandInformationDetails({id: id}).then(res => {
          this.form = res.data
          if (this.form.technicalField != null) {
            this.form.technicalField = this.form.technicalField.split(',').map(item => {
              return Number(item)
            })
          }
        })
      }
      this.drawer = true
    },*/
    handleCheckAllChange() {
      this.formB.technicalField = this.checkAll ? this.cities.map(item => {
        return item.id
      }) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      this.formB.technicalField = value
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    }
  },
  mounted() {
    getDictType(30).then(res=>{
      this.cities=res.data;
    });
    getDictType(87).then(res=>{
      this.categoryList=res.data;
    })
  }
}
</script>

<style lang="scss" scoped>
.addDemand{
  width: 1150px;
  height: 100%;
  padding-bottom: 20px;
  background: #FFFFFF;
  border: 2px solid #EFEFEF;
  margin-left: 110px;
  .demand_content {
    margin-top: 20px;
   // padding: 20px;
  /*  overflow-y: scroll;
    position: relative;*/
    .form-input{
      width: 435px;
    }
    .form-input :hover{
      border-color:#ff9a02;
    }
  }
  .add-heda{
    width: 1148px;
    height: 50px;
    background: #F9F9F9;
    border-bottom: 2px solid #EFEFEF;
    border-right: 2px solid #EFEFEF;
  .add-heda-text{
    width: 87px;
    height: 21px;
    font-size: 21px;
    font-family: Hiragino Sans GB !important;
    font-weight: normal;
    color: #000000;
    line-height: 45px;
    margin-left: 27px;
    font-weight: 600;
  }
  }
}



</style>
<style lang="scss" >
.ant-checkbox-wrapper:first-child{
  padding-left: 10px;
}
.addDemand{
.ant-input {
  height: 46px;
}
.ant-select-selection--single {
  height: 44px;
  margin-right: 10px;
  padding-top: 6px;
  border: 2px solid #E1E1E1;
}
.ant-select:focus {
  .ant-select-selection--single {
    border-color:#ff9a02;
    box-shadow:0px 0px 0px #ff9a02;
  }

  }
.ant-select:hover{
  .ant-select-selection--single {
    border-color:#ff9a02;
  }
}
.ant-form-item-{
  color: #666666 !important;
}
  .a-ant-col .ant-col{
    height: 42px !important;
  }
}
</style>
